var site = window.site || {};

(function ($) {
  site.gdpr = {
    gdprRemInfoMessageBox: function () {
      $('#info-message-box').remove();
    },

    gdprSetInfoBoxPosition: function (infoLink, infoMessageBox, infoMsgArrow, isMobile) {
      var infoLinkTop = infoLink.offset().top + infoLink.height() + 10;
      var infoLinkLeft = infoLink.offset().left;
      var infoMsgMaxWidth = parseInt(infoMessageBox.css('max-width').replace('px', ''), 10);

      if (isMobile) {
        infoMsgArrow.css({
          left: (infoLinkLeft - 10) + 'px'
        });
        infoLinkLeft = 10;
      } else if ((infoMessageBox.outerWidth(true) === infoMsgMaxWidth)
        && ((infoLinkLeft + (infoMsgMaxWidth + 25)) > screen.width)) {
        infoLinkLeft = infoLinkLeft - 480;
        infoMsgArrow.addClass('top-right-arrow');
      }
      infoMessageBox.css({
        top: infoLinkTop + 'px',
        left: infoLinkLeft + 'px'
      });
    },

    gdprToolTip: function (context) {
      if (Drupal.settings.globals_variables.gdpr_compliant) {
        $('body', context).click(function (e) {
          if (e.target.className !== 'info-msg' && e.target.className !== 'info-msg__txt') {
            $('.info-msg').addClass('hidden');
          }
        });
        $('.info-link', context).live('click', function (e) {
          var infoLink = null;
          var infoBoxId = null;
          var infoMessageBox = null;
          var infoMsgArrow = null;
          var isMobile = null;

          e.preventDefault();
          infoLink = $(e.currentTarget);

          site.gdpr.gdprRemInfoMessageBox();

          $("<div id='info-message-box'><div id='info-text'></div></div>").appendTo('body');
          infoBoxId = $('#' + infoLink.attr('message-in'));
          infoMessageBox = $('#info-message-box');
          infoMsgArrow = $('.info-msg__arrow');

          $('#info-text').html("<div id='info-close'>X</div>" + infoBoxId.html());

          $('#cboxClose, #info-close').live('mousedown', function () {
            e.stopPropagation();
            site.gdpr.gdprRemInfoMessageBox();
          });

          isMobile = typeof Unison !== 'undefined' ? Unison.fetch.now().name === 'small' : $.cookie('client.isMobile');

          site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile);
          $(window).on('resize-debounced', site.gdpr.gdprSetInfoBoxPosition(infoLink, infoMessageBox, infoMsgArrow, isMobile));
        });
      }
    }
  };

  Drupal.behaviors.gdpr = {
    attach: function (context) {
      site.gdpr.gdprToolTip(context);
    }
  };
})(jQuery);
